import {PLATFORM} from "aurelia-framework";
export class ModuleLoader {
constructor() {
PLATFORM.moduleName("../erp/views/arbeitszeit/benutzerstatus/benutzerstatus-list-form","arbeitszeit");
PLATFORM.moduleName("../erp/views/arbeitszeit/lohnexport/lohnexport-edit-form");
PLATFORM.moduleName("../erp/views/arbeitszeit/lohnexport/lohnexport-list-form");
PLATFORM.moduleName("../erp/views/arbeitszeit/saldenuebersicht/saldenuebersicht-list-form","arbeitszeit");
PLATFORM.moduleName("../erp/views/arbeitszeit/tagesbuchungen/tagesbuchungen-list-form","arbeitszeit");
PLATFORM.moduleName("../erp/views/dashboard/dashboard-list-form");
PLATFORM.moduleName("../erp/views/dashboard-designer/dashboard-designer-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/dashboard-designer/dashboard-designer-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/diverse/impressum/impressum-form");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamische-entitaet/dynamische-entitaet-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamische-entitaet/dynamische-entitaet-element-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamische-entitaet/dynamische-entitaet-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamisches-feld/dynamisches-feld-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamisches-feld/dynamisches-feld-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamisches-feld/dynamisches-feld-vorlage-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamisches-feld/dynamisches-feld-zu-entitaet-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/dynamische-felder/dynamisches-feld/dynamisches-feld-zu-report-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/einkauf/bestellung/bestellung-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/bestellung/bestellung-list-form");
PLATFORM.moduleName("../erp/views/einkauf/bestellung/bestellungsgruppe-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/bestellung/bestellungsposition-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/bestellvorschlag/bestellvorschlag-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/bestellvorschlag/bestellvorschlag-list-form");
PLATFORM.moduleName("../erp/views/einkauf/eingangsrechnung/eingangsrechnung-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/eingangsrechnung/eingangsrechnung-list-form");
PLATFORM.moduleName("../erp/views/einkauf/eingangsrechnung/eingangsrechnungsposition-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/eingangsrechnung/eingangsrechnungszuschlag-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/warenuebernahme/warenuebernahme-edit-form");
PLATFORM.moduleName("../erp/views/einkauf/warenuebernahme/warenuebernahme-list-form");
PLATFORM.moduleName("../erp/views/einkauf/warenuebernahme/warenuebernahmeposition-edit-form");
PLATFORM.moduleName("../erp/views/kalkulation/berechnungsdefinition/berechnungsdefinition-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/kalkulation/berechnungsdefinition/berechnungsdefinition-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/kalkulation/berechnungsdefinition/berechnungsfeld-kopf-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/kalkulation/berechnungsdefinition/berechnungsfeld-pos-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/kalkulation/berechnungsdefinition/berechnungsversion-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/kalkulation/berechnungsdefinition/berechnungsversion-konditionsfeld-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/kalkulation/kondition/kondition-edit-form");
PLATFORM.moduleName("../erp/views/kalkulation/kondition/kondition-list-form");
PLATFORM.moduleName("../erp/views/kalkulation/kondition/kondition-list-nested-form");
PLATFORM.moduleName("../erp/views/kalkulation/konditionsfeld/konditionsfeld-edit-form");
PLATFORM.moduleName("../erp/views/kalkulation/konditionsfeld/konditionsfeld-list-form");
PLATFORM.moduleName("../erp/views/kassa/kassa-beleg/kassa-beleg-edit-form");
PLATFORM.moduleName("../erp/views/kassa/kassa-beleg/kassa-beleg-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/beleg-import/beleg-import-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/beleg-import/beleg-import-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/datenbank-info/datenbank-info-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/einstellung/einstellung-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fehler/fehler-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fehler/fehler-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-konto/fibu-konto-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-konto/fibu-konto-filter-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-konto/fibu-konto-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-kontogruppe/fibu-kontogruppe-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-kontogruppe/fibu-kontogruppe-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-sync-job/fibu-sync-job-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/fibu-sync-job/fibu-sync-job-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/import/import-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/import/import-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/Instanz/instanz-edit-form");
PLATFORM.moduleName("../erp/views/Konfiguration/Instanz/instanz-list-form");
PLATFORM.moduleName("../erp/views/Konfiguration/status/status-list-form");
PLATFORM.moduleName("../erp/views/lager/artikelverleih/artikelverleih-edit-form");
PLATFORM.moduleName("../erp/views/lager/artikelverleih/artikelverleih-list-form");
PLATFORM.moduleName("../erp/views/lager/inventur/inventur-edit-form");
PLATFORM.moduleName("../erp/views/lager/inventur/inventur-list-form");
PLATFORM.moduleName("../erp/views/lager/inventur/inventurposition-edit-form");
PLATFORM.moduleName("../erp/views/lager/lagerbestand/lagerbestand-detail-list-nested-form");
PLATFORM.moduleName("../erp/views/lager/lagerbestand/lagerbestand-list-nested-form");
PLATFORM.moduleName("../erp/views/lager/lagerbestand/lagerbuchung-info-list-nested-form");
PLATFORM.moduleName("../erp/views/lager/lagerbestand/lagerbuchung-list-nested-form");
PLATFORM.moduleName("../erp/views/lager/lagerkorrektur/lagerkorrektur-edit-form");
PLATFORM.moduleName("../erp/views/lager/lagerkorrektur/lagerkorrektur-list-form");
PLATFORM.moduleName("../erp/views/produktion/produktionsauftrag/produktion-fertigware-edit-form","produktion");
PLATFORM.moduleName("../erp/views/produktion/produktionsauftrag/produktion-rohware-edit-form","produktion");
PLATFORM.moduleName("../erp/views/produktion/produktionsauftrag/produktionsauftrag-edit-form","produktion");
PLATFORM.moduleName("../erp/views/produktion/produktionsauftrag/produktionsauftrag-list-form","produktion");
PLATFORM.moduleName("../erp/views/produktion/produktionsauftrag/produktion-stueckliste-edit-form","produktion");
PLATFORM.moduleName("../erp/views/stammdaten/anfrageart/anfrageart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/anfrageart/anfrageart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/angebotsart/angebotsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/angebotsart/angebotsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/anrede/anrede-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/anrede/anrede-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/arbeitszeit-benutzer-zuteilung/arbeitszeit-benutzer-zuteilung-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/arbeitszeit-benutzer-zuteilung/arbeitszeit-benutzer-zuteilung-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/arbeitszeit-kostenstelle/arbeitszeit-kostenstelle-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/arbeitszeit-kostenstelle/arbeitszeit-kostenstelle-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-einheit-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-kunde-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-lieferant-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-mindestbestand-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikel/artikel-stueckliste-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/artikelart/artikelart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/artikelart/artikelart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/artikelgruppe/artikelgruppe-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/artikelgruppe/artikelgruppe-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/artikelkategorie/artikelkategorie-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/artikelkategorie/artikelkategorie-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/aufgabe/aufgabe-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/aufgabe/aufgabe-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/auftragsart/auftragsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/auftragsart/auftragsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/auswertung/auswertung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/auswertung/auswertung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/benachrichtigung/benachrichtigung-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/benachrichtigung/benachrichtigung-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/benutzer/benutzer-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/benutzer/benutzer-einstellungen-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/benutzer/benutzer-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/benutzerrolle/benutzerrolle-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/benutzerrolle/benutzerrolle-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/bestellungsart/bestellungsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/bestellungsart/bestellungsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/brief/brief-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/brief/brief-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/briefvorlage/briefvorlage-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/briefvorlage/briefvorlage-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/dokumentenart/dokumentenart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/dokumentenart/dokumentenart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/dokumentenart/dokumentenart-zu-entitaet-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/druck-einstellung/druck-einstellung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/druck-einstellung/druck-einstellung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/drucktext/drucktext-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/drucktext/drucktext-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/einheit/einheit-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/einheit/einheit-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/faktura-sammelart/faktura-sammelart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/faktura-sammelart/faktura-sammelart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/filiale/filiale-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/filiale/filiale-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/filiale/filiale-zu-land-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/filtergruppe/filterelement-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/filtergruppe/filtergruppe-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/filtergruppe/filtergruppe-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma/bankverbindung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma/firma-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma/firma-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma/oss-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma/steuerinformation-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma/steuertext-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma-zahlungsverkehr/firma-zahlungsverkehr-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/firma-zahlungsverkehr/firma-zahlungsverkehr-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/geschaeftspartner/bankverbindung-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/geschaeftspartner/geschaeftspartner-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/geschaeftspartner/geschaeftspartner-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/geschaeftspartnerart/geschaeftspartnerart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/geschaeftspartnerart/geschaeftspartnerart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/intercompany/intercompany-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/intercompany/intercompany-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/intrastat-sync/intrastat-sync-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/intrastat-sync/intrastat-sync-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/kalender/kalender-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/kalender/kalender-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/kassa/kassa-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/kassa/kassa-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lager/lager-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lager/lager-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lagerbuchungsart/lagerbuchungsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lagerbuchungsart/lagerbuchungsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/land/land-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/land/land-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lieferbedingung/lieferbedingung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lieferbedingung/lieferbedingung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/lieferbedingung/transportkosten-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mail-import-def/mail-import-def-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mail-import-def/mail-import-def-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mail-import-item/mail-import-item-anhang-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/mail-import-item/mail-import-item-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/mail-import-item/mail-import-item-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/mail-server/mail-server-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mail-server/mail-server-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mail-vorlage/mail-vorlage-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mail-vorlage/mail-vorlage-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mandant/mandant-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mandant/mandant-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/mandant/mandant-wechseln-form");
PLATFORM.moduleName("../erp/views/stammdaten/medien/medien-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/medien/medien-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/newsletter/newsletter-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/newsletter/newsletter-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/newsletter-anmeldung/newsletter-anmeldung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/newsletter-anmeldung/newsletter-anmeldung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/newsletter-empfaenger/newsletter-empfaenger-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/newsletter-empfaenger/newsletter-empfaenger-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/notiz/notiz-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/notiztyp/notiztyp-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/notiztyp/notiztyp-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/nummernkreis/nummernkreis-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/nummernkreis/nummernkreis-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/papierkorb/papierkorb-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/person/person-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/person/person-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/personenaktion/personenaktion-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/personenaktion/personenaktion-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/personenfunktion/personenfunktion-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/personenfunktion/personenfunktion-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/positionsart/positionsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/positionsart/positionsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/projekt/projekt-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/projekt/projekt-list-form");
PLATFORM.moduleName("../erp/views/stammdaten/projekt/projekt-taetigkeit-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/projektart/projektart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/projektart/projektart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/provisionsmodell/provisionsfeld-edit-form");
PLATFORM.moduleName("../erp/views/stammdaten/provisionsmodell/provisionsmodell-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/provisionsmodell/provisionsmodell-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/qr-label/qr-label-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/reklamationsgrund/reklamationsgrund-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/reklamationsgrund/reklamationsgrund-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/skript-item/skript-item-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/skript-item/skript-item-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/spediteur/spediteur-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/spediteur/spediteur-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/spediteur-dienstleistung/spediteur-dienstleistung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/spediteur-dienstleistung/spediteur-dienstleistung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/sprache/sprache-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/sprache/sprache-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/status/status-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/status/status-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/status/status-zu-entitaet-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/steuercode/steuercode-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/steuercode/steuercode-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/steuersatz/steuersatz-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/steuersatz/steuersatz-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/taetigkeit/taetigkeit-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/taetigkeit/taetigkeit-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/taetigkeitenart/taetigkeitenart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/taetigkeitenart/taetigkeitenart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/tag/tag-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/tag/tag-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/tag/tag-zu-entitaet-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/textbaustein/textbaustein-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/textbaustein/textbaustein-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/uid-validierung/uid-validierung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/uid-validierung/uid-validierung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/validierung/validierung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/validierung/validierung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/vertreter/vertreter-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/vertreter/vertreter-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/vertreter/vertreter-zu-provisionsmodell-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/waehrung/waehrung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/waehrung/waehrung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/waehrung/waehrungskurs-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/zahlungsart/zahlungsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/zahlungsart/zahlungsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/zahlungsbedingung/zahlungsbedingung-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/zahlungsbedingung/zahlungsbedingung-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/zuschlagsart/zuschlagsart-edit-form","stammdaten");
PLATFORM.moduleName("../erp/views/stammdaten/zuschlagsart/zuschlagsart-list-form","stammdaten");
PLATFORM.moduleName("../erp/views/statistik/auswertung/auswertung-form","auswertungen");
PLATFORM.moduleName("../erp/views/statistik/statistik-artikel/statistik-artikel-list-form","auswertungen");
PLATFORM.moduleName("../erp/views/statistik/statistik-auftrag/statistik-auftrag-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-bestellungen/statistik-bestellungen-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-ein-auszahlungs-rechnung/statistik-ein-auszahlungs-rechnung-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-faktura/statistik-faktura-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-geschaeftspartner-person/statistik-geschaeftspartner-person-list-form","auswertungen");
PLATFORM.moduleName("../erp/views/statistik/statistik-gruppe/statistik-gruppe-list-form","auswertungen");
PLATFORM.moduleName("../erp/views/statistik/statistik-kassa-info/statistik-kassa-info-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-kunde/statistik-kunde-list-form","auswertungen");
PLATFORM.moduleName("../erp/views/statistik/statistik-lagerbestand/statistik-lagerbestand-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-lagerbewegungen/statistik-lagerbewegungen-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-projektzeit/statistik-projektzeit-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-reklamationen/statistik-reklamationen-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/statistik-webshop-login-log/statistik-webshop-login-log-list-form","Statistik");
PLATFORM.moduleName("../erp/views/statistik/uebersicht/statistik-list-form","auswertungen");
PLATFORM.moduleName("../erp/views/suche/volltext-thesaurus/volltext-thesaurus-edit-form");
PLATFORM.moduleName("../erp/views/suche/volltext-thesaurus/volltext-thesaurus-list-form");
PLATFORM.moduleName("../erp/views/tickets/ticket/ticket-edit-form","ticket");
PLATFORM.moduleName("../erp/views/tickets/ticket/ticket-list-form","ticket");
PLATFORM.moduleName("../erp/views/tickets/version/version-list-form","ticket");
PLATFORM.moduleName("../erp/views/verkauf/angebot/angebot-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/angebot/angebot-list-form");
PLATFORM.moduleName("../erp/views/verkauf/angebot/angebotsgruppe-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/angebot/angebotsposition-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/auftrag/abschlagsfaktura-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/auftrag/auftrag-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/auftrag/auftrag-list-form");
PLATFORM.moduleName("../erp/views/verkauf/auftrag/auftragsgruppe-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/auftrag/auftragsposition-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/faktura/faktura-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/faktura/fakturagruppe-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/faktura/faktura-list-form");
PLATFORM.moduleName("../erp/views/verkauf/faktura/fakturaposition-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/gutschein/gutschein-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/gutschein/gutschein-list-form");
PLATFORM.moduleName("../erp/views/verkauf/kundenreklamation/Kundenreklamation-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/kundenreklamation/kundenreklamation-list-form");
PLATFORM.moduleName("../erp/views/verkauf/kundenreklamation/kundenreklamationsposition-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/ladeliste/ladeliste-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/ladeliste/ladeliste-list-form");
PLATFORM.moduleName("../erp/views/verkauf/lieferschein/lieferschein-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/lieferschein/lieferscheingruppe-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/lieferschein/lieferschein-list-form");
PLATFORM.moduleName("../erp/views/verkauf/lieferschein/lieferscheinposition-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/mahnung/mahnung-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/mahnung/mahnung-list-form");
PLATFORM.moduleName("../erp/views/verkauf/provision/provision-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/provision/provision-list-form");
PLATFORM.moduleName("../erp/views/verkauf/provision/provisionsposition-edit-form");
PLATFORM.moduleName("../erp/views/verkauf/zahlung/zahlung-edit-form","verkauf");
PLATFORM.moduleName("../erp/views/verkauf/zahlung/zahlung-list-form");
PLATFORM.moduleName("../erp/views/webshop/seite/webshop-seite-edit-form","webshop");
PLATFORM.moduleName("../erp/views/webshop/seite/webshop-seite-list-form","webshop");
PLATFORM.moduleName("../erp/views/zahlungsverkehr/zahlungsverkehr/zahlungsverkehr-edit-form");
PLATFORM.moduleName("../erp/views/zahlungsverkehr/zahlungsverkehr/zahlungsverkehr-list-form");
PLATFORM.moduleName("../erp/views/zahlungsverkehr/zahlungsverkehr-sync-job/zahlungsverkehr-sync-job-edit-form");
PLATFORM.moduleName("../erp/views/zeiterfassung/projektplanung/projektplanung-list-form","projektzeit");
PLATFORM.moduleName("../erp/views/zeiterfassung/projekt-taetigkeit-buchung/projekt-taetigkeit-buchung-edit-form","projektzeit");
PLATFORM.moduleName("../erp/views/zeiterfassung/projekt-taetigkeit-buchung/projekt-taetigkeit-buchung-list-form","projektzeit");
PLATFORM.moduleName("../framework/login/views/activate-account/activate-account-form");
PLATFORM.moduleName("../framework/login/views/login/login-form");
PLATFORM.moduleName("../framework/login/views/password-reset/password-reset-form");
}
}
