import { autoinject, transient } from "aurelia-framework";
import { OnAngebotspositionArtikelErsetzen, OnAngebotspositionArtikelErsetzenEvent, OnAngebotspositionVariableStuecklisteAuswahl, OnAngebotspositionVariableStuecklisteAuswahlEvent, OnAngebotspositionVariablerSetartikelManuellerPreisEntfernenEvent, OnAngebotspositionVariablerSetartikelPreisAendernEvent, OnAngebotspositionVariablerSetartikelRabattEntfernenEvent, OnEvalVerkaufArtikeldaten, OnEvalVerkaufArtikeldatenEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { FormBase, ICommandData, ModelUtilsService, PopupInfoService } from "../../../../framework/forms/export";
import { IKalkulationRefreshOptions } from "../../../interfaces/kalkulation-refresh-options";
import { ArtikelService, DynFelderDataService, KalkulationRefreshService, StartupService } from "../../../services/export";
import { OnAngebotspositionVariableStuecklistenkomponentenErstellen, OnAngebotspositionVariableStuecklistenkomponentenErstellenEvent } from './../../../../framework-data/events';
import { VariablerSetartikelKomponentenAuswahl } from './../../../elements/variabler-setartikel-komponenten-auswahl/variabler-setartikel-komponenten-auswahl';
import { IVariablerSetartikelPreisAendernResult, VariablerSetartikelPreisAendern } from './../../../elements/variabler-setartikel-preis-aendern/variabler-setartikel-preis-aendern';
import { DxCustomizeService } from './../../../services/dx-customize-service';
import { DynFelderService } from './../../../services/dyn-felder-service';
import { DynFeldLogikTyp } from "../../../enumerations/dyn-feld-logik-typ";

@autoinject
@transient()
export class AngebotspositionEditUtils {
  constructor(
    public dynFelderDataService: DynFelderDataService,
    public startupService: StartupService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _artikelService: ArtikelService,
    private _dynFelderService: DynFelderService,
    private _modelUtilsService: ModelUtilsService,
    private _webEventService: WebEventService,
    private _popupInfoService: PopupInfoService,
    private _localizationService: LocalizationService,
    private _dxCustomizeService: DxCustomizeService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  idArtikelErsetzen: number;
  showVariablerSetartikelCheckbox: boolean;
  variablerSetartikelKomponentenAuswahlList: any[];
  onReloadStuecklistemkomponentenlist: boolean;

  showArtikelErsetzenCommand: ICommandData = {
    id: "artikelErsetzenCommand",
    idCategory: "angebotsposition",
    icon: "exchange",
    title: "angebot.artikel_ersetzen",
    isEnabledExpression: "!!models.data.$m_Angebotsposition && !!models.data.$m_Angebotsposition.CanSave && !!models.data.$m_Angebotsposition.Id && !models.data.$m_Angebotsposition.IsPositionVariableStuecklistenkomponente",
    sortIndex: 990,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      this.idArtikelErsetzen = null;

      await this.form["r_artikelErsetzenPopup"].instance.show();

      const artikel = this.form["r_artikelErsetzenElement"];
      artikel.artikelNummer.instance.focus();
    }
  }
  doArtikelErsetzen: ICommandData = {
    id: "doArtikelErsetzen",
    title: "angebot.artikel_ersetzen",
    icon: "exchange",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnAngebotspositionArtikelErsetzenEvent({
        IdAngebotsposition: this.form.models.data.$m_Angebotsposition.Id,
        IdArtikel: this.idArtikelErsetzen
      });

      if (!this.idArtikelErsetzen) {
        DevExpress.ui.notify(this._localizationService.translateOnce("angebot.kein_artikel_ausgewaehlt"), "ERROR", 3000);
        return;
      }

      const webEventResult: OnAngebotspositionArtikelErsetzen = await this._webEventService.execute(webEvent, true);
      DevExpress.ui.notify(this._localizationService.translateOnce("angebot.artikel_erfolgreich_ersetzt"), "SUCCESS", 3000);
      this._popupInfoService.closeAllPopups();
    }
  }

  showVariablerSetartikelKomponentenAuswahlCommand: ICommandData = {
    id: "variablerSetartikelKomponentenAuswahlCommand",
    idCategory: "angebotsposition",
    icon: "list",
    title: "angebot.stueckliste_auswaehlen",
    isVisibleExpression: "functions.$f_AngebotspositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Angebotsposition && models.data.$m_Angebotsposition.Id > 0 && models.data.$m_Angebotsposition.IsVariablerSetartikel && models.data.$m_Angebotsposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelStuecklistenKomponentenAuswahl();
    }
  }

  showVariablerSetartikelPreisAendernCommand: ICommandData = {
    id: "variablerSetartikelPreisAendernCommand",
    idCategory: "angebotsposition",
    icon: "money",
    title: "angebot.preis_aendern",
    isVisibleExpression: "functions.$f_AngebotspositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Angebotsposition && models.data.$m_Angebotsposition.Id > 0 && models.data.$m_Angebotsposition.IsVariablerSetartikel && models.data.$m_Angebotsposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelPreisAendernAuswahl();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Angebotsposition",
      idVorgEntitaetPropertyName: "IdArtikel"
    });

    this.refreshOptions = {
      form: form,
      modelName: "$m_Angebotsposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeVerpEH"
    }

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "angebotspositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    this.form.models.onLoaded.register(async (e) => {
      if (e == void 0 || e.model.id != "$m_Angebotsposition" || !e.data) {
        return;
      }

      this.showVariablerSetartikelCheckbox = this.form.models.data.$m_Angebotsposition
        && this.form.models.data.$m_Angebotsposition.Artikel != null
        && this.form.models.data.$m_Angebotsposition.Artikel.BezugTyp == 3
        ? true
        : false;

      this.refreshAngebotsgruppeButton();
    });
    this.form.onSaving.register(async (r) => {
      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }
      if (!this.form.models.data.$m_Angebotsposition.IsVariablerSetartikel) {
        return;
      }

      if (r.form.models.data.$m_Angebotsposition.Id > 0) {
        return;
      }

      if (this.variablerSetartikelKomponentenAuswahlList != null) {
        return;
      }

      this.showVariablerSetartikelStuecklistenKomponentenAuswahl();

      return Promise.reject();
    });

    this.form.onSaved.register(async (r) => {
      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }
      if (this.variablerSetartikelKomponentenAuswahlList == null && this.form.models.data.$m_Angebotsposition.IsSetartikel) {
        if (this.onReloadStuecklistemkomponentenlist) {
          this.onReloadStuecklistemkomponentenlist = false;
          this.form.models.reloadAll();
        }
        return;
      }

      if (r.form.models.data.$m_Angebotsposition.Id > 0 && !this.form.models.data.$m_Angebotsposition.IsVariablerSetartikel) {
        return;
      }

      const webEvent = new OnAngebotspositionVariableStuecklistenkomponentenErstellenEvent({
        IdAngebotsposition: r.form.models.data.$m_Angebotsposition.Id,
        StuecklistenkomponentenMengeList: this.variablerSetartikelKomponentenAuswahlList
      });

      const result: OnAngebotspositionVariableStuecklistenkomponentenErstellen = await this._webEventService.execute(webEvent);

      this.form.models.reloadAll();
      this.variablerSetartikelKomponentenAuswahlList = null;
    });
  }

  async idArtikelChanged(e) {
    this._kalkulationRefreshService.onIdArtikelChanged(this.refreshOptions);

    if (!this.form.models.data.$m_Angebotsposition.Id) {
      await this.dynFelderDataService.loadVorbelegungen();
      this._modelUtilsService.setDirty(this.form.models.data.$m_Angebotsposition);
    }

    this.doEvalVerkaufArtikeldaten(e.detail.value);

    if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
      return;
    }

    if (e.detail.value != null) {
      const artikel = await this._artikelService.getArtikelById(e.detail.value);

      this.form.models.data.$m_Angebotsposition.IsVariablerSetartikel = false;
      this.showVariablerSetartikelCheckbox = false;
      this.variablerSetartikelKomponentenAuswahlList = null;

      if (artikel.BezugTyp != 3) {
        return;
      }

      const dynFeldEintrag = await this._dynFelderService.loadDynFeldEintragByLogikttyp(artikel.IdDynamischesFeldContainer, DynFeldLogikTyp.VariablerSetartikel);
      this.showVariablerSetartikelCheckbox = true;

      if (dynFeldEintrag != null && dynFeldEintrag[0].WertNummer == 1) {
        this.form.models.data.$m_Angebotsposition.IsVariablerSetartikel = true;
      }
    }
  }

  copyAngebotsposition($delegate: { (customData: any): Promise<any> }) {
    $delegate({ "PosNrUeberschreiben": true })
  }

  private async showVariablerSetartikelStuecklistenKomponentenAuswahl() {
    const webEvent = new OnAngebotspositionVariableStuecklisteAuswahlEvent({
      IdArtikel: this.form.models.data.$m_Angebotsposition.IdArtikel,
      IdAngebotsposition: this.form.models.data.$m_Angebotsposition.Id
    });

    const result: OnAngebotspositionVariableStuecklisteAuswahl = await this._webEventService.execute(webEvent);

    if (result == void 0) {
      return;
    }

    if (result.StuecklistenkomponentenAuswahlList.length == 0) {
      return;
    }

    const variablerSetartikelAuswahlPopup: VariablerSetartikelKomponentenAuswahl = this.form["r_variablerSetartikelKomponentenAuswahlElement"];

    variablerSetartikelAuswahlPopup.show({
      StuecklisteList: result.StuecklistenkomponentenAuswahlList,
      callback: async (auswahl) => {
        this.variablerSetartikelKomponentenAuswahlList = auswahl;
        this.form.save();
      }
    });
  }

  private async showVariablerSetartikelPreisAendernAuswahl() {
    const variablerSetartikelPreisAendernPopup: VariablerSetartikelPreisAendern = this.form["r_variablerSetartikelPreisAendernElement"];

    variablerSetartikelPreisAendernPopup.show({
      callback: async (result: IVariablerSetartikelPreisAendernResult) => {
        if (result.RabattEntfernen) {
          const webEvent = new OnAngebotspositionVariablerSetartikelRabattEntfernenEvent({
            IdAngebotspositionSetartikel: this.form.models.data.$m_Angebotsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else if (result.ManuellerPreisEntfernen) {
          const webEvent = new OnAngebotspositionVariablerSetartikelManuellerPreisEntfernenEvent({
            IdAngebotspositionSetartikel: this.form.models.data.$m_Angebotsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else {
          const webEvent = new OnAngebotspositionVariablerSetartikelPreisAendernEvent({
            IdAngebotspositionSetartikel: this.form.models.data.$m_Angebotsposition.Id,
            Typ: result.KalkulationsFeldTyp,
            Wert: result.Wert
          });

          await this._webEventService.execute(webEvent);
        }

        this.form.models.reloadAll();
      }
    });
  }

  private async doEvalVerkaufArtikeldaten(idArtikel) {
    if (!idArtikel) {
      return;
    }

    const webEvent = new OnEvalVerkaufArtikeldatenEvent({
      IdArtikel: idArtikel,
      IdKunde: this.form.models.data.$m_Angebot.IdKunde
    });

    const result: OnEvalVerkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    this.form.models.data.$m_Angebotsposition.IdEinheit = result.IdArtikelEinheit;
    this.form.models.data.$m_Angebotsposition.KundeArtikelNr = result.KundeArtikelNr;
    this.form.models.data.$m_Angebotsposition.KundeArtikelbezeichnung = result.KundeArtikelbezeichnung;
  }

  onReloadStuecklistenkomponentenChanged(e) {
    if (!this.form.models.data.$m_Angebotsposition) {
      return;
    }

    if (this.form.models.data.$m_Angebotsposition.IsSetartikel && e.previousValue != null) {
      this.onReloadStuecklistemkomponentenlist = true;
    }
  }

  onAngebotsgruppeChanged() {
    this.refreshAngebotsgruppeButton();
  }

  private refreshAngebotsgruppeButton() {
    const data = this.form.models.data.$m_Angebotsposition;
    if (!data) {
      return;
    }
    const editor = this.form["r_angebotsgruppe"].instance;
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (!data.IdAngebotsgruppe && data.CanSave) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: async () => {
            this.form.models.data.$m_AngebotsgruppeEdit = {
              Id: 0
            };

            this.form.editPopups.showEx({
              idEditPopup: "angebotsgruppeEditPopup",
              modelLoaded: (form, ev) => {
                if (!form.models.data.$m_Angebotsgruppe) {
                  return;
                }
                form.models.data.$m_Angebotsgruppe.IdAngebot = this.form.models.data.$m_Angebotsposition.IdAngebot;
              },
              closeCallback: async (form) => {
                this.form["r_angebotsgruppe"].instance.getDataSource().reload();

                const model = form.models.modelWithKeyId;
                const data = form.models.data[model.id];
                this.form.models.data.$m_Angebotsposition.IdAngebotsgruppe = data.Id;

                this.form.onEditorValueChanged.fire({
                  binding: {
                    dataContext: "$m_Angebotsposition",
                    bindTo: "IdAngebotsgruppe"
                  },
                  value: data.Id
                });
              }
            });
          }
        }
      });
    }

    buttons.push({
      location: "after",
      name: "clear"
    }, {
      location: "after",
      name: "dropDown"
    });

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }
}
