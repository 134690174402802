import { autoinject } from "aurelia-framework";
import { IdxPopupComponent, IdxDataGridComponent } from '../../interfaces/export';
import { GlobalizationService, DataSourceService, ScopeContainer, ShortcutService } from '../../../framework/base/export';
import { SimpleWidgetCreatorService, PopupInfoService, RouterService, FormBase, IModel } from '../../../framework/forms/export';
import { EntitaetInfoService } from '../../services/export';

@autoinject
export class Aendprot {
  constructor(
    private _globalizationService: GlobalizationService,
    private _dataSourceService: DataSourceService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _shortcutService: ShortcutService,
    private _popupInfoService: PopupInfoService,
    private _routeService: RouterService,
    private _entitaetInfoService: EntitaetInfoService
  ) { }

  scopeContainer: ScopeContainer;

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "1100px",
    height: "800px"
  };

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      dataField: "TransactionId",
      caption: "Transaktion",
      width: "90px"
    }, {
      dataField: "Eigenschaft",
      caption: "Eigenschaft",
      width: "120px"
    }, {
      dataField: "WertAlt",
      caption: "Wert alt",
      minWidth: 100
    }, {
      dataField: "WertNeu",
      caption: "Wert neu",
      minWidth: 100
    }, {
      dataField: "AnlageDatum",
      caption: "Datum",
      format: this._globalizationService.getFormatterParser("G"),
      width: "130px"
    }, {
      dataField: "AnlageBenutzerName",
      caption: "Benutzer",
      width: "120px"
    }],
    filterRow: {
      visible: true
    },
    headerFilter: {
      visible: true
    },
    height: "100%"
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "aendProtToolbar",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      caption: "aendprot.aenderungsprotokoll",
      commands: []
    });

    this._shortcutService.bindShortcut("ctrl+f1", () => {
      if (this._popupInfoService.isPopupOpen()) {
        const popup = this._popupInfoService.getCurrentPopup();
        if (popup && popup.form) {
          this.getIdAndShow(popup.form);
        }
      } else {
        if (this._routeService.currentViewItem && this._routeService.currentViewItem.controller) {
          const controller: any = this._routeService.currentViewItem.controller;
          const viewModel = controller.currentViewModel;
          if (viewModel && viewModel instanceof FormBase) {
            this.getIdAndShow(viewModel);
          }
        }
      }
    }, true);
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  private getIdAndShow(formBase: FormBase) {
    const model = formBase.models.getModelWithKeyId();
    if (!model) {
      return;
    }

    const data = formBase.models.data[model.id];
    if (!data) {
      return;
    }

    if (!data.Id) {
      return;
    }

    const idList = [data.Id];
    idList.push(...this.getExtendedIds(model, data));

    this.show(idList);
  }
  private getExtendedIds(model: IModel, data: any): number[] {
    const result = [];

    if (data.IdKundeAdresse) {
      result.push(data.IdKundeAdresse);
    }
    if (data.IdRechnungsortAdresse) {
      result.push(data.IdRechnungsortAdresse);
    }
    if (data.IdLieferortAdresse) {
      result.push(data.IdLieferortAdresse);
    }
    if (data.IdLieferantAdresse) {
      result.push(data.IdLieferantAdresse);
    }
    if (data.IdAdresse) {
      result.push(data.IdAdresse);
    }
    if (data.IdSteuer) {
      result.push(data.IdSteuer);
    }
    if (data.DynamischesFeldContainer != null) {
      data.DynamischesFeldContainer.DynamischesFeldEintraege.forEach((element) => {
        if (element.Id != null) {
          result.push(element.Id);
        }
      });
    }

    return result;
  }

  private show(idList: number[]) {
    const where = [];
    idList.forEach(v => {
      where.push(["IdObject", v], "or");
    });
    where.splice(where.length - 1, 1);

    const dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      webApiAction: "ERP/Stammdaten/AendProt",
      webApiWhere: where,
      webApiOrderBy: [{ columnName: "AnlageDatum", sortOrder: 1 }]
    }
    );

    if (this.grid) {
      this.grid.instance.option("dataSource", dataSource);
    } else {
      this.gridOptions.dataSource = dataSource;
    }

    this.popup.instance.show();
  }
}
